<template lang="pug">
	.sidebar(@click.stop='()=>{}')
		.main-menu
			.wrapper-menu.mb-3
				router-link.navbar-logo(:to='logoLink' @click.native='updatePage(menu[0])')
					.logo
			.wrapper-menu
				user-project-select(v-if='me && !me.isPartner' @click="$emit('click', $event)")
				ul.mb-0.main-menu--list.list-unstyled
					li.b3(v-for='(item,index) in menu' @click="$emit('click', $event)" :data-flag='item.id')
						hr.hr-line(v-if='item.isLine')
						a.b3(v-else-if='item.newWindow' :href='item.to' rel='noopener noreferrer' target='_blank')
							i(:class='item.icon')
							| {{ $t(item.label) }}
						a.b3(v-else-if='item.subs && item.subs.length > 0' @click.prevent='openSubMenu($event,item)' :href='`#${item.to}`')
							i(:class='item.icon')
							| {{ $t(item.label) }}
						.b3.main-button(v-else-if='item.to.button == true' :class='{"disabled": checkDisabled(item.fnDisable), "d-none": checkHide(item.fnHide)}' @click="$bvModal.show(item.to.id)")
							i(:class='item.icon')
							| {{ $t(item.label) }}
						router-link(
							v-else-if='item.to.params && item.to.name.includes("project_")'
							:class='{"disabled": isDisabled || checkDisabled(item.fnDisable), "d-none": checkHide(item.fnHide)}'
							@click.native='changeSelectedParentHasNoSubmenu(item.id)'
							:to='item.to')

							i(:class='item.icon')
							| {{ $t(item.label) }}

						router-link.b3(
							v-else
							:class='{"disabled": checkDisabled(item.fnDisable), "d-none": checkHide(item.fnHide)}'
							@click.native='changeSelectedParentHasNoSubmenu(item.id)'
							:to='item.to')
							i(:class='item.icon')
							| {{ $t(item.label) }}

			.main-menu--footer.d-flex.align-items-center.wrapper-menu
				.flex-grow-1
					.float-left
						img.avatar-30(v-if='me.avatar' :alt='me.tgUsername' :src='me.avatar.contentUrl')
						div.avatar-default.avatar-30(v-else-if='me' :style='me.avatarBg') {{ me.tgUsername.charAt(0) | uppercase }}
				theme-switch.mb-0.mr-2.small-theme-switch
				locale-switch-simple.small-locale-switch

			p.m-0.b4.text-grey.rules-text(v-html="$t('h1.serviceRules3')")

		modal-general(:id="'modal-support'" :title="$t('h1.support')" :contentClass="'support'")
			support
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { menuHiddenBreakpoint, subHiddenBreakpoint, defaultColor } from '../constants/config';
import { MenuFabric } from '@/constants/menu';
import UserProjectSelect from '@/components/Common/UserProjectSelect';
import LocaleSwitchSimple from '@/components/Common/LocaleSwitchSimple';
import ThemeSwitch from '@/components/Common/ThemeSwitch';
import { bus } from '@/main.js';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import IconClose from '@/components/Common/IconClose';
import Support from '@/components/Common/Support';

export default {
    components: {
        LocaleSwitchSimple,
        ThemeSwitch,
        UserProjectSelect,
        ModalGeneral,
        Support,
        IconClose,
    },
    data() {
        return {
            selectedParentMenu: '',
            menu: MenuFabric.getItems(),
            viewingParentMenu: '',
            isDarkTheme: false,
            modelColor: false,
        };
    },
    created() {
        const color = this.getThemeColor();
        this.isDarkTheme = color.indexOf('dark') > -1;
        if (this.openedProject) this.menu = MenuFabric.getItems(this.openedProject);
    },
    computed: {
        ...mapGetters({
            menuType: 'getMenuType',
            menuClickCount: 'getMenuClickCount',
            selectedMenuHasSubItems: 'getSelectedMenuHasSubItems',
            lastOpenedProjectId: 'project/lastOpenedProjectId',
            projects: 'project/itemsShort',
            openedProject: 'project/opened',
            isModalProjectsShown: 'modals/projects',
        }),

        projectHasResources() {
            return this.openedProject && this.openedProject.canSeeMenuPlans();
        },

        isProfileFilled() {
            if (this.me && this.me.isPartner) return true;

            return this.me && this.me.hasFilledAccount();
        },
        isProjectReady() {
            if (!this.openedProject) return false;

            return this.openedProject.isNeuro()
                ? this.openedProject.hasBot() &&
                      this.me &&
                      this.me.hasFilledAccount() &&
                      this.me.hasFilledPaymentAccounts()
                : this.openedProject.isReady() &&
                      this.me &&
                      this.me.hasFilledAccount() &&
                      this.me.hasFilledPaymentAccounts();
        },

        canSeeMenuItems() {
            if (!this.openedProject) return false;

            return this.openedProject.canSeeMenuItems();
        },

        logoLink() {
            if (this.me && this.me.isPartner) return { name: 'offer' };
            return this.lastOpenedProjectId ? this.menu[0].to : { name: 'add_project' };
        },

        isDisabled() {
            return !this.lastOpenedProjectId || !this.openedProject || !this.openedProject.id;
        },
    },
    mounted() {
        this.selectMenu();
        window.addEventListener('resize', this.handleWindowResize);
        document.addEventListener('click', this.handleDocumentClick);
        this.handleWindowResize();
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleDocumentClick);
        window.removeEventListener('resize', this.handleWindowResize);
    },
    methods: {
        ...mapMutations(['changeSideMenuStatus', 'addMenuClassname', 'changeSelectedMenuHasSubItems']),
        setMenu() {
            this.$nextTick(() => (this.menu = MenuFabric.getItems(this.openedProject)));
        },
        checkDisabled(fn) {
            if (fn) {
                return !this[fn];
            }

            return false;
        },
        checkHide(fn) {
            if (fn) {
                return !this[fn];
            }

            return false;
        },
        updatePage(item) {
            bus.$emit('updatePage', item.to);
        },
        selectMenu() {
            const currentParentUrl = _.last(this.$route.path.split('/').filter(x => x !== ''));

            if (currentParentUrl !== undefined || currentParentUrl !== null) {
                this.selectedParentMenu = currentParentUrl.toLowerCase();
            } else {
                this.selectedParentMenu = 'dashboard';
            }
            this.isCurrentMenuHasSubItem();
        },
        isCurrentMenuHasSubItem() {
            const menuItem = this.menu.find(x => x.id === this.selectedParentMenu);
            const isCurrentMenuHasSubItem = menuItem && menuItem.subs && menuItem.subs.length > 0 ? true : false;
            if (isCurrentMenuHasSubItem != this.selectedMenuHasSubItems) {
                if (!isCurrentMenuHasSubItem) {
                    this.changeSideMenuStatus({
                        step: 0,
                        classNames: this.menuType,
                        selectedMenuHasSubItems: false,
                    });
                } else {
                    this.changeSideMenuStatus({
                        step: 0,
                        classNames: this.menuType,
                        selectedMenuHasSubItems: true,
                    });
                }
            }

            return isCurrentMenuHasSubItem;
        },

        changeSelectedParentHasNoSubmenu(parentMenu) {
            this.selectedParentMenu = parentMenu;
            this.viewingParentMenu = parentMenu;
            this.changeSelectedMenuHasSubItems(false);
            this.changeSideMenuStatus({
                step: 0,
                classNames: this.menuType,
                selectedMenuHasSubItems: false,
            });
        },

        openSubMenu(e, menuItem) {
            const selectedParent = menuItem.id;
            const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
            this.changeSelectedMenuHasSubItems(hasSubMenu);
            if (!hasSubMenu) {
                this.viewingParentMenu = selectedParent;
                this.selectedParentMenu = selectedParent;
                this.toggle();
            } else {
                const currentClasses = this.menuType ? this.menuType.split(' ').filter(x => x !== '') : '';

                if (!currentClasses.includes('menu-mobile')) {
                    if (
                        currentClasses.includes('menu-sub-hidden') &&
                        (this.menuClickCount === 2 || this.menuClickCount === 0)
                    ) {
                        this.changeSideMenuStatus({
                            step: 3,
                            classNames: this.menuType,
                            selectedMenuHasSubItems: hasSubMenu,
                        });
                    } else if (
                        currentClasses.includes('menu-hidden') &&
                        (this.menuClickCount === 1 || this.menuClickCount === 3)
                    ) {
                        this.changeSideMenuStatus({
                            step: 2,
                            classNames: this.menuType,
                            selectedMenuHasSubItems: hasSubMenu,
                        });
                    } else if (
                        currentClasses.includes('menu-default') &&
                        !currentClasses.includes('menu-sub-hidden') &&
                        (this.menuClickCount === 1 || this.menuClickCount === 3)
                    ) {
                        this.changeSideMenuStatus({
                            step: 0,
                            classNames: this.menuType,
                            selectedMenuHasSubItems: hasSubMenu,
                        });
                    }
                } else {
                    this.addMenuClassname({
                        classname: 'sub-show-temporary',
                        currentClasses: this.menuType,
                    });
                }
                this.viewingParentMenu = selectedParent;
            }
        },
        handleDocumentClick(e) {
            this.viewingParentMenu = '';
            this.selectMenu();
            this.toggle();
        },
        toggle() {
            const currentClasses = this.menuType.split(' ').filter(x => x !== '');
            if (currentClasses.includes('menu-sub-hidden') && this.menuClickCount === 3) {
                this.changeSideMenuStatus({
                    step: 2,
                    classNames: this.menuType,
                    selectedMenuHasSubItems: this.selectedMenuHasSubItems,
                });
            } else if (currentClasses.includes('menu-hidden') || currentClasses.includes('menu-mobile')) {
                if (!(this.menuClickCount === 1 && !this.selectedMenuHasSubItems)) {
                    this.changeSideMenuStatus({
                        step: 0,
                        classNames: this.menuType,
                        selectedMenuHasSubItems: this.selectedMenuHasSubItems,
                    });
                }
            }
        },
        // Resize
        handleWindowResize(event) {
            if (event && !event.isTrusted) {
                return;
            }
            let nextClasses = this.getMenuClassesForResize(this.menuType);
            this.changeSideMenuStatus({
                step: 0,
                classNames: nextClasses.join(' '),
                selectedMenuHasSubItems: this.selectedMenuHasSubItems,
            });
        },
        getMenuClassesForResize(classes) {
            let nextClasses = classes.split(' ').filter(x => x !== '');
            const windowWidth = window.innerWidth;

            if (windowWidth < menuHiddenBreakpoint) {
                nextClasses.push('menu-mobile');
            } else if (windowWidth < subHiddenBreakpoint) {
                nextClasses = nextClasses.filter(x => x !== 'menu-mobile');
                if (nextClasses.includes('menu-default') && !nextClasses.includes('menu-sub-hidden')) {
                    nextClasses.push('menu-sub-hidden');
                }
            } else {
                nextClasses = nextClasses.filter(x => x !== 'menu-mobile');
                if (nextClasses.includes('menu-default') && nextClasses.includes('menu-sub-hidden')) {
                    nextClasses = nextClasses.filter(x => x !== 'menu-sub-hidden');
                }
            }
            return nextClasses;
        },
        getThemeColor() {
            return localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : defaultColor;
        },
    },
    watch: {
        $route(to, from) {
            if (to.path !== from.path) {
                const toParentUrl = to.name;
                if (toParentUrl !== undefined || toParentUrl !== null) {
                    this.selectedParentMenu = toParentUrl.toLowerCase();
                } else {
                    this.selectedParentMenu = 'dashboard';
                }
                this.selectMenu();
                this.toggle();
                this.changeSideMenuStatus({
                    step: 0,
                    classNames: this.menuType,
                    selectedMenuHasSubItems: this.selectedMenuHasSubItems,
                });

                window.scrollTo(0, top);

                if (to.name.includes('project_') && to.params && to.params.id) {
                    this.$nextTick(() => (this.menu = MenuFabric.getItems(this.openedProject)));
                }
            }
        },
        modelColor(val) {
            let color = this.getThemeColor();

            if (this.isDarkTheme) {
                color = color.replace('dark', 'light');
            } else {
                color = color.replace('light', 'dark');
            }

            localStorage.setItem('themeColor', color);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        },

        openedProject: {
            handler(n) {
                this.setMenu();
            },
            deep: true,
        },
        me: {
            handler() {
                this.setMenu();
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.sidebar {
    position: relative;
    min-height: 100vh;

    &::before {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 272px;
        left: -100vh;
        background-color: var(--foreground-color);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 1px;
        background-color: var(--brand-card-border);
    }

    @include respond-below(md) {
        &::after {
            content: none;
        }
    }
}

.main-menu {
    &__item {
        &-bot,
        &-features,
        &-audience {
            width: 24px;
            height: 24px;
            background: center / cover no-repeat;
            transition: background-image 200ms !important;
        }
    }

    a.active > .main-menu__item-bot {
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 22C20.5 22 22.5 20 22.5 15V9C22.5 4 20.5 2 15.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5Z' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 9.75C15.1716 9.75 14.5 9.07843 14.5 8.25C14.5 7.42157 15.1716 6.75 16 6.75C16.8284 6.75 17.5 7.42157 17.5 8.25C17.5 9.07843 16.8284 9.75 16 9.75Z' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 9.75C8.17157 9.75 7.5 9.07843 7.5 8.25C7.5 7.42157 8.17157 6.75 9 6.75C9.82843 6.75 10.5 7.42157 10.5 8.25C10.5 9.07843 9.82843 9.75 9 9.75Z' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.1 13.3008C16.6 13.3008 17 13.7008 17 14.2008C17 16.6908 14.99 18.7008 12.5 18.7008C10.01 18.7008 8 16.6908 8 14.2008C8 13.7008 8.4 13.3008 8.9 13.3008H16.1Z' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
    }

    a.active > .main-menu__item-features {
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.79938 16.8802C6.49938 15.4902 4.60938 12.7802 4.60938 9.90018C4.60938 4.95018 9.15938 1.07018 14.2994 2.19018C16.5594 2.69018 18.5394 4.19018 19.5694 6.26018C21.6594 10.4602 19.4594 14.9202 16.2294 16.8702V18.0302C16.2294 18.3202 16.3394 18.9902 15.2694 18.9902H9.75938C8.65938 19.0002 8.79938 18.5702 8.79938 18.0402V16.8802Z' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 21.9992C11.29 21.3492 13.71 21.3492 16 21.9992' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ") !important;
    }

    a.active > .main-menu__item-audience {
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.33055 10.8973C6.95055 10.8173 5.06055 8.86734 5.06055 6.46734C5.06055 4.01734 7.04055 2.02734 9.50055 2.02734C11.9505 2.02734 13.9405 4.01734 13.9405 6.46734C13.9305 8.86734 12.0405 10.8173 9.66055 10.8973C9.56055 10.8873 9.44055 10.8873 9.33055 10.8973Z' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.9093 4.02734C18.8493 4.02734 20.4093 5.59734 20.4093 7.52734C20.4093 9.41734 18.9093 10.9573 17.0393 11.0273C16.9593 11.0173 16.8693 11.0173 16.7793 11.0273' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.6607 20.4573C7.4107 22.2973 11.9207 22.2973 14.6707 20.4573C17.0907 18.8373 17.0907 16.1973 14.6707 14.5873C11.9307 12.7573 7.4207 12.7573 4.6607 14.5873C2.2407 16.2073 2.2407 18.8473 4.6607 20.4573Z' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.8398 20.0273C19.5598 19.8773 20.2398 19.5873 20.7998 19.1573C22.3598 17.9873 22.3598 16.0573 20.7998 14.8873C20.2498 14.4673 19.5798 14.1873 18.8698 14.0273' stroke='%2352A7F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
    }
}
</style>
