import { render, staticRenderFns } from "./ProjectView.vue?vue&type=template&id=160b438c&scoped=true&lang=pug"
import script from "./ProjectView.vue?vue&type=script&lang=js"
export * from "./ProjectView.vue?vue&type=script&lang=js"
import style0 from "./ProjectView.vue?vue&type=style&index=0&id=160b438c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160b438c",
  null
  
)

export default component.exports