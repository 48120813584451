<template lang="pug">
	nav.navbar.fixed-top.align-items-center.d-lg-none.justify-content-between
			a.d-xs-block.d-lg-none(v-if='me && me.id' href='#' v-b-modal.modalMobileMenu @click="$bvModal.hide('project-selection')")
				mobile-menu-icon
			.avatar-rect-wrapper.d-xs-block.d-lg-none(v-if='openedProject && !openedProject.isNeuro()' @click='openModalProjects')
				.avatar-rect.avatar-rect-sm.no-border
					img.avatar-rect-img(v-if='openedProject.image' :src='openedProject.image.contentUrl' :alt='openedProject.title')

			.avatar-rect-neuro.avatar-rect-neuro--nav(v-if='openedProject && openedProject.isNeuro()')
				img.avatar-rect-img(v-if="openedProject.image" :src='openedProject.image.contentUrl' :alt='openedProject.title')
				span.avatar-rect-span(v-if="!openedProject.image && openedProject && openedProject.title") {{ `${openedProject.title.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').slice(0, 1)}` }}
				img.avatar-rect-img(v-else src='/assets/img/neurobots/neurobot.webp' alt='Neurobot')
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { MenuFabric } from '@/constants/menu';
import { bus } from '@/main.js';
import { MobileMenuIcon } from '../components/Svg';
import { searchPath, menuHiddenBreakpoint, buyUrl, defaultColor } from '../constants/config';

export default {
    components: {
        'mobile-menu-icon': MobileMenuIcon,
    },
    data() {
        return {
            menu: MenuFabric.getItems(),
            searchKeyword: '',
            isMobileSearch: false,
            isSearchOver: false,
            fullScreen: false,
            menuHiddenBreakpoint,
            searchPath,
            buyUrl,
            notifications: [],
            modelColor: false,
        };
    },
    methods: {
        ...mapMutations(['changeSideMenuStatus', 'changeSideMenuForMobile']),
        ...mapActions({
            signOut: 'auth/logout',
        }),
        openModalProjects() {
            return this.$store.commit('modals/setProjects', {});
        },
        updatePage(item) {
            bus.$emit('updatePage', item.to);
        },
        search() {
            this.$router.push(`${this.searchPath}?search=${this.searchKeyword}`);
            this.searchKeyword = '';
        },
        searchClick() {
            if (window.innerWidth < this.menuHiddenBreakpoint) {
                if (!this.isMobileSearch) {
                    this.isMobileSearch = true;
                } else {
                    this.search();
                    this.isMobileSearch = false;
                }
            } else {
                this.search();
            }
        },
        handleDocumentforMobileSearch() {
            if (!this.isSearchOver) {
                this.isMobileSearch = false;
                this.searchKeyword = '';
            }
        },

        toggleFullScreen() {
            const isInFullScreen = this.isInFullScreen();

            var docElm = document.documentElement;
            if (!isInFullScreen) {
                if (docElm.requestFullscreen) {
                    docElm.requestFullscreen();
                } else if (docElm.mozRequestFullScreen) {
                    docElm.mozRequestFullScreen();
                } else if (docElm.webkitRequestFullScreen) {
                    docElm.webkitRequestFullScreen();
                } else if (docElm.msRequestFullscreen) {
                    docElm.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
            this.fullScreen = !isInFullScreen;
        },
        getThemeColor() {
            return localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : defaultColor;
        },
        isInFullScreen() {
            return (
                (document.fullscreenElement && document.fullscreenElement !== null) ||
                (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
                (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
                (document.msFullscreenElement && document.msFullscreenElement !== null)
            );
        },
    },
    computed: {
        ...mapGetters({
            menuType: 'getMenuType',
            menuClickCount: 'getMenuClickCount',
            selectedMenuHasSubItems: 'getSelectedMenuHasSubItems',
            lastOpenedProjectId: 'project/lastOpenedProjectId',
            openedProject: 'project/opened',
        }),

        logoLink() {
            if (!this.me) return { name: 'index' };
            if (this.me.isPartner) return { name: 'offer' };

            return this.lastOpenedProjectId ? this.menu[0].to : { name: 'add_project' };
        },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleDocumentforMobileSearch);
    },
    created() {},
    watch: {
        '$i18n.locale'(to, from) {
            if (from !== to) {
                window.location.reload();
            }
        },
        $route(to, from) {
            if (to.path !== from.path) {
                if (to.name.includes('project_') && to.params && to.params.id) {
                    this.$nextTick(() => (this.menu = MenuFabric.getItems()));
                }
            }
        },
        isMobileSearch(val) {
            if (val) {
                document.addEventListener('click', this.handleDocumentforMobileSearch);
            } else {
                document.removeEventListener('click', this.handleDocumentforMobileSearch);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar-rect-wrapper {
    height: 23px;

    .avatar-rect {
        border: none;
    }
}
</style>
