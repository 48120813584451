<template lang="pug">
	.d-flex.flex-column.support
		.mb-3.support__card
			p.b3.mb-3.text-grey {{ $t('support.study') }}
			//a.d-block.mb-3(href="/" target="_blank")
				pw-btn.support__button(:text="$t('📋 FAQ')" :icon="'smiley'")
			a.d-block.text-decoration-none(:href="linkToTutorial" target="_blank")
				pw-btn.support__button(:text="`📚 ${$t('h1.tutorials')}`" :icon="'smiley'")

		.mb-3.support__card
			p.b3.mb-3.text-grey {{ $t('support.write') }}
			a.d-block.mb-3.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
				pw-btn.support__button(:text="$t('h1.telegram')" :icon="'icon-telegram'")
			pw-btn-mail.support__button

		.support__card
			p.b3.mb-3.text-grey {{ $t('support.suggest') }}
			a.d-block.mb-3.text-decoration-none(:href="`https://t.me/${$t('contacts.developer_tg')}`" target="_blank")
				pw-btn.support__button(:text="$t('h1.devDep')" :icon="'icon-telegram'")

		span.btn.btn-primary.mt-3(v-if="isMobile" @click="close") {{ $t('resources.btnFine') }}
</template>

<script>
import PwBtn from '@/components/Buttons/PwBtn';
import PwBtnMail from '@/components/Buttons/PwBtnMail';

export default {
    name: 'Support',
    components: {
        PwBtn,
        PwBtnMail,
    },
    data: () => ({}),
    computed: {
        linkToTutorial() {
            if (!this.me) return '/';

            return this.me.isPartner
                ? 'https://docs.paywall.pw/rus/obshee/partnerskaya-programma'
                : this.$t('contacts.knowledge_base');
        },
    },
    methods: {
        close() {
            this.$root.$emit('bv::hide::modal', 'modal-support');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.support {
    &__card {
        padding: 15px;
        border: 1px solid rgba(82, 167, 249, 0.4);
        border-radius: var(--border-radius-rounded);
    }

    &__button {
        max-width: 290px;

        @include respond-below(sm) {
            max-width: 100%;
        }
    }
}
</style>
